// Define your variants outside of the hook to prevent recreation on each render
const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
        opacity: 1,
        transition: {
            staggerChildren: 0.1,
        },
    },
};

const itemVariants = {
    hidden: { y: -50, opacity: 0 },
    visible: {
        y: 0,
        opacity: 1,
        transition: {
            type: 'spring',
            stiffness: 120,
        },
    },
};

export const usePageTransition = () => {
    return { containerVariants, itemVariants };
};
