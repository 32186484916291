import React from 'react';
import { motion } from 'framer-motion';
import { usePageTransition } from '../usePageTransition'; // Adjust the import path as necessary
import WorldMap from './WorldMap'; // Adjust the import path as necessary

function Travel() {
    const { containerVariants } = usePageTransition();

    return (
        <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit="hidden"
            style={{ textAlign: 'center', marginTop: '50px' }}
        >
            <WorldMap />
        </motion.div>
    );
}

export default Travel;
