import {
  Button,
  Container,
  CssBaseline,
  Grid,
  ThemeProvider,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  useNavigate,
} from "react-router-dom";
import CircleTransition from "./CircleTransition";
import backgroundImage from "./bg.png";
import Experience from "./pages/experience/Experience";
import Guides from "./pages/guides/Guides";
import Travel from "./pages/travel/Travel";
import theme from "./theme";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Routes>
          <Route path="/" element={<NavigationPage />} />
          <Route path="/travel" element={<Travel />} />
          <Route path="/guides" element={<Guides />} />
          <Route path="/experience" element={<Experience />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

const buttonStyle = {
  fontFamily: "Roboto, sans-serif",
  fontWeight: 300,
  fontSize: "1.6rem",
  width: "16vw", // Adjust the width of the buttons
  height: "6vh", // Adjust the height of the buttons
  position: "relative",
  borderRadius: "0.5rem", // Add rounded corners to the buttons
  textTransform: "none", // Remove default text transformation
  boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)", // Add a subtle box shadow
};

function NavigationPage() {
  const navigate = useNavigate();
  const [isAnimating, setIsAnimating] = useState(false);
  const [initialPosition] = useState({
    x: window.innerWidth / 2,
    y: window.innerHeight / 2,
  });
  const [circleColor, setCircleColor] = useState("");
  const [typedText, setTypedText] = useState("");
  const [buttonBounds, setButtonBounds] = useState(null);
  const [animationInterval, setAnimationInterval] = useState(null);

  const handleNavigation = (path, color) => {
    setCircleColor(color);
    setIsAnimating(true);

    setTimeout(() => {
      setIsAnimating(false);
      navigate(path);
    }, 800);
  };

  const handleMouseEnter = (text, targetBounds, event) => {
    setButtonBounds(targetBounds);

    // Clear any existing interval
    if (animationInterval) {
      clearInterval(animationInterval);
      setAnimationInterval(null);
    }

    let index = 0;
    const animateText = () => {
      setTypedText(text.substring(0, index));
      index++;
      if (index <= text.length) {
        setTimeout(animateText, 10); // Adjust the delay as needed
      }
    };
    animateText();
  };

  const handleMouseLeave = () => {
    setButtonBounds(null);
    setTypedText(""); // Reset the typedText
  };

  return (
    <Container
      maxWidth={false}
      disableGutters={true}
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        minWidth: "100vw",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "4rem",
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Typography
        variant="h3"
        component="h1"
        gutterBottom
        align="center"
        style={{
          fontFamily: "Roboto, sans-serif",
          fontWeight: 300,
          fontSize: "4.2rem",
          marginBottom: "2rem",
          letterSpacing: "0.05em",
        }}
      >
        Welcome to
        <span
          style={{
            backgroundImage:
              "linear-gradient(72.83deg, #99CCFF 11.63%, #CC99FF 40.43%, #FF9999 68.07%)",
            WebkitBackgroundClip: "text",
            backgroundClip: "text",
            WebkitTextFillColor: "transparent",
            color: "transparent",
            letterSpacing: "0.05em",
          }}
        >
          {" "}
          deanmulhol.land!
        </span>
      </Typography>

      <Grid container spacing={2} justifyContent="center">
        <Grid item>
          <Button
            variant="contained"
            sx={{
              ...buttonStyle,
              backgroundColor: "#f44336",
              color: "#fff",
              ":hover": {
                transform: "scale(1.05)",
                transition: "transform 0.3s",
                backgroundColor: "#f44336",
              },
            }}
            onMouseEnter={(e) =>
              handleMouseEnter(
                "Travel tracker for the Countries I've been to",
                e.currentTarget.getBoundingClientRect(),
              )
            }
            onMouseLeave={handleMouseLeave}
            onClick={() => handleNavigation("/travel", "#f44336")}
          >
            Travel
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            sx={{
              ...buttonStyle,
              backgroundColor: "#9c27b0",
              color: "#fff",
              ":hover": {
                transform: "scale(1.05)",
                transition: "transform 0.3s",
                backgroundColor: "#9c27b0",
              },
            }}
            onMouseEnter={(e) =>
              handleMouseEnter(
                "Guides/Tips on various topics, primarily software related!",
                e.currentTarget.getBoundingClientRect(),
              )
            }
            onMouseLeave={handleMouseLeave}
            onClick={() => handleNavigation("/guides", "#9c27b0")}
          >
            Guides
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            sx={{
              ...buttonStyle,
              backgroundColor: "#4caf50",
              color: "#fff",
              ":hover": {
                transform: "scale(1.05)",
                transition: "transform 0.3s",
                backgroundColor: "#4caf50",
              },
            }}
            onMouseEnter={(e) =>
              handleMouseEnter(
                "My personal professional experience",
                e.currentTarget.getBoundingClientRect(),
              )
            }
            onMouseLeave={handleMouseLeave}
            onClick={() => handleNavigation("/experience", "#4caf50")}
          >
            Experience
          </Button>
        </Grid>
      </Grid>

      {buttonBounds && (
        <Typography
          variant="subtitle1"
          align="center"
          sx={{
            fontFamily: "Roboto, sans-serif",
            fontWeight: 300,
            fontSize: "1.2rem",
            position: "absolute",
            top: `${buttonBounds.bottom}px`,
            left: `${buttonBounds.left}px`, // Align the text with the left of the button
            maxWidth: `${buttonBounds.width}px`,
            whiteSpace: "normal",
            overflow: "hidden",
          }}
        >
          {typedText}
        </Typography>
      )}

      {isAnimating && (
        <CircleTransition
          isAnimating={isAnimating}
          onAnimationComplete={() => {}}
          initialPosition={initialPosition}
          color={circleColor}
          secondaryColor={theme.palette.background.default}
        />
      )}
    </Container>
  );
}

export default App;
