import React, { useState, useEffect } from "react";
import { ComposableMap, Geographies, Geography, ZoomableGroup } from "react-simple-maps";
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import './WorldMap.css'; // Assuming your CSS for the map container and full screen is defined here

const geoUrl = "https://raw.githubusercontent.com/nvkelso/natural-earth-vector/master/geojson/ne_10m_admin_0_countries_iso.geojson";

const WorldMap = () => {
    const [countries, setCountries] = useState([]);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [position, setPosition] = useState({ coordinates: [0, 0], zoom: 1 });
    const [visitedCountries, setVisitedCountries] = useState([]);
    useEffect(() => {
        fetch(geoUrl)
            .then(response => response.json())
            .then(data => setCountries(data.features))
            .catch(error => console.log("Error loading the map data: ", error));
        fetch("/countries_visited.json")
            .then(response => response.json())
            .then(data => setVisitedCountries(data))
            .catch(error => console.log("Error loading visited countries data: ", error));
    }, []);

    const toggleFullScreen = () => {
        setIsFullScreen(!isFullScreen);
    };

    const handleMoveEnd = (newPosition) => {
        setPosition(newPosition);
    };

    return (
        <div className={isFullScreen ? "mapContainer fullScreen" : "mapContainer"}>
            <IconButton
                className="fullscreenButton"
                onClick={toggleFullScreen}
                style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    zIndex: 10,
                }}

            >
                {isFullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </IconButton>
            <ComposableMap>
                <ZoomableGroup
                    zoom={position.zoom}
                    center={position.coordinates}
                    onMoveEnd={handleMoveEnd}
                >
                    <Geographies geography={countries}>
                        {({ geographies }) =>
                            geographies.map((geo) => {
                                const visitedInfo = visitedCountries.find(vc => vc.Country === geo.properties.ADMIN);
                                // Construct the URL for the flag image using the ISO_A2 code from the geojson
                                const flagUrl = `https://flagcdn.com/32x24/${geo.properties.ISO_A2.toLowerCase()}.png`;

                                // Tooltip content including the flag image and country information
                                const title = (
                                    <>
                                        <img src={flagUrl} alt={`${geo.properties.ADMIN} flag`} style={{ marginRight: 5, width: 20, height: 15, verticalAlign: 'middle' }} />
                                        {geo.properties.ADMIN} {visitedInfo?.Visited ? `(${visitedInfo.Year})` : ''}
                                    </>
                                );

                                return (
                                    <Tooltip key={geo.rsmKey} title={title} placement="top" arrow>
                                        <Geography geography={geo}
                                                   style={{
                                                       default: { fill: visitedInfo?.Visited ? "#4caf50" : "#FAF9F6", outline: "none", strokeWidth: 0.5 },
                                                       hover: { fill: "#F53", outline: "none" },
                                                       pressed: { fill: "#E42" , outline: "none" },
                                                   }}
                                        />
                                    </Tooltip>
                                );
                            })
                        }
                    </Geographies>
                </ZoomableGroup>
            </ComposableMap>
        </div>
    );
};

export default WorldMap;
