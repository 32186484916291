import React from 'react';
import {motion} from 'framer-motion';
import PropTypes from 'prop-types';


const CircleTransition = ({ isAnimating, onAnimationComplete, initialPosition, color, secondaryColor }) => {    // Calculate the scale needed to cover the entire screen
    const calculateScale = () => {
        // Distances to each corner from the button
        const distances = [
            Math.hypot(initialPosition.x, initialPosition.y), // Top left corner
            Math.hypot(window.innerWidth - initialPosition.x, initialPosition.y), // Top right corner
            Math.hypot(initialPosition.x, window.innerHeight - initialPosition.y), // Bottom left corner
            Math.hypot(window.innerWidth - initialPosition.x, window.innerHeight - initialPosition.y), // Bottom right corner
        ];

        // Maximum distance to a corner
        const maxDistance = Math.max(...distances);

        // Scale is twice the maximum distance divided by the diameter of the circle (initial size)
        return (2 * maxDistance) / 100; // 100 is the initial diameter of the circle
    };

    const circleVariants = {
        initial: {
            scale: 0,
            left: '50%',
            top: '50%',
            translateX: '-50%',
            translateY: '-50%',
            position: 'fixed',
            borderRadius: '50%',
            width: 100,
            height: 100,
        },
        animate: (custom) => ({
            scale: [0, calculateScale()],
            translateX: '-50%',
            translateY: '-50%',
            backgroundColor: [custom.color, custom.secondaryColor], // Animate color change
            transition: {
                duration: 0.7,
                delay: custom.delay, // Use delay passed via `custom` prop
            },
        }),
    };

    return (
        <>
            {isAnimating && (
                <motion.div
                    custom={{ color, secondaryColor: color, delay: 0 }} // First circle starts immediately with the button color
                    initial="initial"
                    animate="animate"
                    variants={circleVariants}
                    onAnimationComplete={onAnimationComplete}
                />
            )}
            {isAnimating && (
                <motion.div
                    custom={{ color: secondaryColor, secondaryColor, delay: 0.1 }} // Second circle starts with a delay, changes to background color
                    initial="initial"
                    animate="animate"
                    variants={circleVariants}
                    onAnimationComplete={onAnimationComplete}
                />
            )}
        </>
    );
};

CircleTransition.propTypes = {
    isAnimating: PropTypes.bool.isRequired,
    onAnimationComplete: PropTypes.func,
    initialPosition: PropTypes.shape({
        x: PropTypes.number.isRequired,
        y: PropTypes.number.isRequired,
    }).isRequired,
    color: PropTypes.string.isRequired,
    secondaryColor: PropTypes.string.isRequired,
};

export default CircleTransition;
