import React from 'react';
import { motion } from 'framer-motion';
import { usePageTransition } from '../usePageTransition'; // Adjust the import path as necessary

function Guides() {
    const { containerVariants, itemVariants } = usePageTransition();

    const duckImageUrl = 'https://www.treehugger.com/thmb/g-DBaMaN9i_byti5UFTsmDLFdYk=/4560x3924/filters:fill(auto,1)/duckling-close-up-500315849-572917c93df78ced1f0b99ec.jpg';

    return (
        <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit="hidden"
            style={{ textAlign: 'center', marginTop: '50px' }}
        >
            <motion.h1 variants={itemVariants}>This Page is Under Construction</motion.h1>
            <motion.img src={duckImageUrl} alt="Under Construction Duck" variants={itemVariants} style={{ marginTop: '20px', maxWidth: '50%', height: 'auto' }} />
            <motion.p variants={itemVariants}>This page isn't available yet</motion.p>
        </motion.div>
    );
}

export default Guides;
